import MktHeader from "~/components/MktHeader";
import MktFooter from "~/components/MktFooter";

export default function MktLayout({ children }: { children: React.ReactNode }) {
    return (
        <>
            <MktHeader />
            {children}
            <MktFooter />
        </>
    );
}